import styled, { css } from '../../utils/styled.ts';
import { ColorName, fallbackFonts } from '../../styles';

export type TextProps<T extends TextTagName = 'p'> = {
  component?: T;
  variant?: Variant;
  weight?: Weight;
  letterSpacing?: LetterSpacing;
  color?: ColorName;
  align?: Align;
} & JSX.IntrinsicElements[T];

const Text = <T extends TextTagName>({
  component,
  variant,
  weight,
  letterSpacing,
  color,
  align,
  ...props
}: TextProps<T>) => {
  return (
    // @ts-expect-error styled-component conflict
    <StyledText
      {...(component ? { as: component } : {})}
      $variant={variant || tagVariantMap[component || 'p']}
      $weight={weight}
      $letterSpacing={letterSpacing}
      $color={color}
      $align={align}
      {...props}
    />
  );
};

type TextTagName = keyof Pick<
  HTMLElementTagNameMap,
  'a' | 'legend' | 'label' | 'strong' | 'span' | 'p' | 'h1' | 'h2'
>;
type LetterSpacing = 'normal' | 'small';
type Variant = 'small' | 'normal' | 'h2' | 'h1';
type Weight = 'thin' | 'normal' | 'medium' | 'semi-bold' | 'bold';
type Align = 'left' | 'center' | 'right';

const tagVariantMap: {
  [key in TextTagName]: Variant;
} = {
  a: 'normal',
  legend: 'normal',
  label: 'normal',
  span: 'normal',
  p: 'normal',
  strong: 'normal',
  h2: 'h2',
  h1: 'h1',
};

const letterSpacingStyles: {
  [key in LetterSpacing]: ReturnType<typeof css>;
} = {
  small: css`
    letter-spacing: -0.9px;
  `,
  normal: css`
    letter-spacing: normal;
  `,
};

const weightStyles: {
  [key in Weight]: ReturnType<typeof css>;
} = {
  thin: css`
    font-weight: 100;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 100;
    }
  `,
  normal: css`
    font-weight: 400;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 400;
    }
  `,
  medium: css`
    font-weight: 500;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 500;
    }
  `,
  'semi-bold': css`
    font-weight: 600;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 600;
    }
  `,
  bold: css`
    font-weight: 700;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 700;
    }
  `,
};

const variantStyles: {
  [key in Variant]: ReturnType<typeof css>;
} = {
  small: css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['small']};
    font-size: 12px;
    line-height: ${17 / 12};
  `,
  normal: css`
    font-size: 16px;
    line-height: ${21 / 16};
    font-weight: normal;
  `,
  h2: css`
    font-size: 20px;
    line-height: 1;
    font-weight: normal;
  `,
  h1: css`
    font-size: 32px;
    line-height: ${37.5 / 32};
    font-weight: normal;
  `,
};
/*${({ theme }) => theme.getDownMedia('sm')} {
      font-size: ${({ theme }) => theme.pxToRem(20)};
    }*/

const StyledText = styled.p<{
  $variant?: Variant;
  $weight?: Weight;
  $letterSpacing?: LetterSpacing;
  $color?: ColorName;
  $align?: Align;
}>`
  margin-top: 0;
  margin-bottom: 0;
  font-family: Roboto, ${fallbackFonts};
  ${({ $variant = 'normal' }) => variantStyles[$variant]};
  ${({ $weight }) => $weight && weightStyles[$weight]};
  ${({ $letterSpacing }) =>
    $letterSpacing && letterSpacingStyles[$letterSpacing]};
  ${({ $align }) =>
    !!$align &&
    css`
      text-align: ${$align};
    `};
  color: ${({ theme, $color }) => theme.getColor($color || 'text')};
`;

export { Text };
