/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createApi } from '../src/utils/api.ts';
import { App } from '../src/components';

const init = (rootId = 'root', { apiUrl, langquest }: InitConfig) => {
  createApi(apiUrl, langquest);
  ReactDOM.createRoot(document.getElementById(rootId)!).render(
    React.createElement(React.StrictMode, {
      children: React.createElement(App, {
        rootId,
      }),
    })
  );
};

type InitConfig = {
  apiUrl: string;
  langquest: string;
};

if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
  // @ts-ignore
  window['aiConsultantLibInit'] = init;
}

export { init };
