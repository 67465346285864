import {resetButtonStyles} from "../../styles";
import styled from "../../utils/styled";

interface InfoSectionProps {
  toggleInfoSection: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({ toggleInfoSection }) => {
  return (
    <StyledInfoSection>
      <InfoSectionTitle>About QuestPair Research Assistant (Beta)</InfoSectionTitle>
      <InfoText>
        The QuestPair Research Assistant is your AI-powered guide to finding the best lab equipment for your research.
        Designed to streamline the decision-making process, it provides expert insights and tailored recommendations to help
        you choose the right tools efficiently.
      </InfoText>
      <InfoText>With the QuestPair Research Assistant, you can:</InfoText>
      <InfoList>
        <InfoListItem>Get personalized recommendations: Receive AI-driven suggestions based on your specific research requirements.</InfoListItem>
        <InfoListItem>Compare options effortlessly: Evaluate different equipment choices side by side to make informed decisions in minutes.</InfoListItem>
        <InfoListItem>Access expert knowledge: Get insights on specifications, compatibility, and best practices to ensure the right fit for your lab.</InfoListItem>
      </InfoList>
      <InfoText>
        Simply type your research question or request in the chat, and the assistant will provide you with relevant information and guidance. Our AI consultant is ready to assist you in optimizing your research setup!
      </InfoText>
      <CloseInfoButton onClick={toggleInfoSection}>
        Close
      </CloseInfoButton>
    </StyledInfoSection>
  );
};

// New Info Section Components
const StyledInfoSection = styled.div`
  padding: 16px;
  background-color: #f5f7f7;
  border-bottom: 1px solid #3C8D8E;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const InfoSectionTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 12px 0;
  color: #3C8D8E;
`;

const InfoText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 12px 0;
  color: #333;
`;

const InfoList = styled.ul`
  margin: 0 0 12px 0;
  padding-left: 20px;
`;

const InfoListItem = styled.li`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 4px;
  color: #333;
`;

const CloseInfoButton = styled.button`
  ${resetButtonStyles};
  background-color: #3C8D8E;
  color: white;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background-color: #317778;
  }
`;

export default InfoSection;
