import color from 'color';
import { externalLinkProps } from '../../utils/consts.ts';
import styled, { css } from '../../utils/styled.ts';
import { resetButtonStyles, fallbackFonts } from '../../styles';

export type ButtonProps<T extends ButtonOrLink = 'button'> = {
  as?: T extends ButtonOrLink ? T : never;
  withExternalLinkProps?: boolean;
  variant?: Variant;
  size?: Size;
} & JSX.IntrinsicElements[T];

const Button = <T extends ButtonOrLink = 'button'>({
  as,
  withExternalLinkProps = true,
  variant = 'contained',
  size = 'normal',
  children,
  ...props
}: ButtonProps<T>) => (
  // @ts-expect-error styled-component conflict
  <Elem
    as={as}
    $variant={variant}
    $size={size}
    {...(as === 'a' && withExternalLinkProps ? externalLinkProps : {})}
    {...props}
  >
    {children}
  </Elem>
);

type ButtonOrLink = 'button' | 'a';

type Variant = 'outlined' | 'contained';
type Size = 'normal' | 'small';

const getDefStyles: (disabled?: boolean) => {
  [key in Variant]: ReturnType<typeof css>;
} = (disabled) => ({
  contained: css`
    color: #fff;
    background: ${({ theme }) =>
      theme.getColor(disabled ? 'silverChalice' : 'accentPrimary')};

    ${!disabled &&
    css`
      &:hover {
        background: ${({ theme }) =>
          color(theme.getColor('accentPrimary')).darken(0.3).hex()};
      }
    `};
  `,
  outlined: css`
    border: 1px solid
      ${({ theme }) =>
        theme.getColor(disabled ? 'silverChalice' : 'accentPrimary')};
    color: ${({ theme }) =>
      theme.getColor(disabled ? 'silverChalice' : 'accentPrimary')};

    ${!disabled &&
    css`
      &:hover {
        border-color: ${({ theme }) => color(theme.getColor('accentPrimary')).darken(0.3).hex()});
        color: ${({ theme }) => color(theme.getColor('accentPrimary')).darken(0.3).hex()});
      }
    `}
  `,
});

const Elem = styled.button<{
  $variant: Variant;
  $size: Size;
}>`
  ${resetButtonStyles};
  display: block;
  padding: ${({ $size }) => ($size === 'normal' ? '12px 22px' : '8px 20px')};
  border-radius: 25px;
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  text-decoration: none;
  text-align: center;
  font-size: ${({ $size }) => ($size === 'normal' ? 20 : 18)}px;
  font-family: Roboto, ${fallbackFonts};
  ${({ $variant, disabled }) => getDefStyles(disabled)[$variant]};

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    font-size: ${({ $size }) => ($size === 'normal' ? 20 : 18)}px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: 100%;
    padding: 12px 22px;
  }
`;

export { Button };
