import { ComponentProps, useState } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { useUpdateEffect } from 'react-use';
import { callAIConsultantApi } from '../../utils/api.ts';
import { resetButtonStyles } from '../../styles';
import { ThumbsUpIcon, ThumbsDownIcon } from '../icons';

export type MessageRatingProps = ComponentProps<typeof Wrapper> & {
  messageId: number;
  removeMessage?: () => void;
};

const MessageRating = ({
  messageId,
  removeMessage,
  ...props
}: MessageRatingProps) => {
  const [active, setActive] = useState<Nullish<State>>(null);

  useUpdateEffect(() => {
    if (!active) return;

    callAIConsultantApi('rateanswers', {
      data: {
        id: messageId,
        rate: active === 'good' ? 5 : 0,
      },
    })
      .then((value) => {
        if (value.data.status === 'error') {
          setActive(null);
        }
      })
      .catch((e) => {
        console.log(e);
        setActive(null);
      });

    if (active === 'bad' && isFunction(removeMessage)) {
      removeMessage();
    }
  }, [messageId, active]);

  if (!messageId) return null;

  return (
    <Wrapper {...props}>
      {buttons.map((type) => (
        <Button
          key={type}
          $active={type === active}
          onClick={() => {
            setActive(type);
          }}
        >
          {type === 'good' ? <ThumbsUpIcon /> : <ThumbsDownIcon />}
        </Button>
      ))}
    </Wrapper>
  );
};

const buttons = ['good', 'bad'] as const;

type State = (typeof buttons)[number];

const Wrapper = styled('div')`
  display: flex;
`;

const Button = styled.button<{
  $active: boolean;
}>`
  ${resetButtonStyles};
  color: ${({ theme, $active }) =>
    theme.getColor($active ? 'accentPrimary' : 'silverChalice')};

  svg {
    width: 20px;
    height: 20px;

    &:last-child {
      margin-left: 5px;
    }
  }
`;

export { MessageRating };
