import React from 'react';
import { isEmpty } from 'lodash-es';
import { Message, MessageModel } from '@questpair/chat-ui-kit-react';
import { ContactsBlock } from '../components/blocks';
import { ProductsBlock } from '../components/blocks/ProductsBlock.tsx';
import { Answer } from './api.ts';

export type ChatMessage = MessageModel & {
  id?: number;
  productsSuggestion?: boolean;
  hidden?: boolean;
};

export const initialMessage: ChatMessage = {
  type: 'text',
  position: 'last',
  direction: 'incoming',
  payload:
    'Hi, I am the QuestPair AI consultant and I can help you to find the equipment which fits your need.',
};

/*
export const convertChatMessageToBackend = (
  chatMessage: MessageModel,
  restart: 0 | 1 = 0
): {
  question: string;
  restart: 0 | 1;
} => ({
  question: chatMessage.message ?? '',
  restart,
});
*/

export const convertBackendMessageToChat: (
  backendMessage: Answer
) => ChatMessage = (backendMessage) => {
  const content: Partial<MessageModel> = (() => {
    switch (true) {
      case !isEmpty(backendMessage.items):
        return {
          type: 'custom',
          payload: React.createElement(Message.CustomContent, {
            children: React.createElement(ProductsBlock, {
              products: backendMessage.items!,
            }),
          }),
          productsSuggestion: true,
        };
      case backendMessage.requestContacts:
        return {
          type: 'custom',
          payload: React.createElement(Message.CustomContent, {
            children: React.createElement(ContactsBlock, {
              titleMessage: backendMessage.message,
            }),
          }),
        };
      default:
        return {
          type: 'text',
          payload: backendMessage.message,
        };
    }
  })();

  return {
    id: backendMessage.id,
    position: 'last',
    direction: backendMessage.owner === 'user' ? 'outgoing' : 'incoming',
    sentTime: backendMessage.date,
    ...content,
  };
};
