import { isEmpty } from 'lodash-es';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

let API: Nullish<AxiosInstance> = null;

export const createApi = (baseURL?: string, langquest?: string) => {
  API = axios.create({
    baseURL: baseURL || import.meta.env.VITE_CONSULTANT_API_URL,
    withCredentials: true,
    headers: {
      'langquest': langquest || import.meta.env.VITE_CONSULTANT_LANGQUEST || 'langquest',
    }
  });
};

/*{
  title: 'Nikon Labophot Microscope',
  description: 'This listing is for 1 of 2 Nikon Labophot Microscope',
  url: 'facilities-and-equipment/laboratory-equipment/microscopy-equipment/microscopes/nikon-labophot-microscope-used-lab-machines-ltd-2376',
  price: null,
  cover: {
    '@id': '/api/media_object/737d6bab-528a-4350-a484-f1a4cfb0dcb3',
    '@type': 'MediaObject',
    id: '737d6bab-528a-4350-a484-f1a4cfb0dcb3',
    uuid: '737d6bab-528a-4350-a484-f1a4cfb0dcb3',
    S: '/api/media_object/737d6bab-528a-4350-a484-f1a4cfb0dcb3/S',
    M: '/api/media_object/737d6bab-528a-4350-a484-f1a4cfb0dcb3/M',
    L: '/api/media_object/737d6bab-528a-4350-a484-f1a4cfb0dcb3/L',
    Original: '/api/media_object/737d6bab-528a-4350-a484-f1a4cfb0dcb3/O',
    purpose: 'cover',
    description: '',
  },
}*/

/*
export type AnswerItemOld = {
  title: string;
  description: string;
  url: string;
  price: null;
  cover: {
    '@id': string;
    '@type': string;
    id: string;
    uuid: string;
    S: string;
    M: string;
    L: string;
    Original: string;
    purpose: 'cover';
    description: string;
  };
};
*/

/*
const AnswerItem = {
  brand: 'Bio-Rad',
  className: 'label-new',
  country: 'France',
  cover:
    '/47/47648e2a9408571b0a7e77453506e26114f7aee118ade8c028c342b2e175de04_300x250.jpg',
  description:
    'Bio-Rad Phd System Lab Analyzer with PR3100 Sunrise' +
    ' absorbance microplate reader\r\n\r\nWith computer\r\nwithout installation cd',
  isQuickBuy: false,
  model: 'Bio-Rad Mini-Protean TGX',
  price: null,
  title:
    'Bio-Rad Phd System Lab Analyzer with PR3100 Sunrise absorbance' +
    ' microplate reader',
  url: 'https://dev.questpair.com/marketplace/facilities-and-equipment/laboratory-equipment/microplate-equipment/microplate-readers/bio-rad-phd-system-lab-analyzer-with-pr3100-sunrise-absorbance-microplate-reader-biomatyx-2056',
  vat: '0.00',
};
*/

export type AnswerItem = {
  title: string;
  reason: string;
  description: string;
  url: string;
  brand: string;
  country: string;
  cover: string;
  className:
    | 'label-new'
    | 'label-instock'
    | 'label-trusted'
    | 'label-external'
    | 'label-unavailable';
  isQuickBuy: boolean;
  model: string;
  price: string | null;
  price_eur: string | null;
  currency: string | null;
  vat: string;
};

export type Answer = {
  id: number;
  message: string;
  requestContacts: boolean;
  // "date": "2024-02-04T20:57:58.000000Z",
  date: string;
  read: boolean;
  owner: 'user' | 'bot' | 'system';
  items: AnswerItem[] | null;
};

type ApiCallData = {
  active: {
    response: {
      active: boolean;
      SSID: string;
      captcha: boolean;
    };
    requestData: {
      turnstile: string | null;
    };
  };
  restart: {
    response: {
      status: 'ok' | 'error';
    };
    requestData: undefined;
  };
  contacts: {
    response: {
      status: 'ok' | 'error';
      message: '';
    };
    requestData: {
      user_id: string | null;
      name: string;
      surname: string | null;
      company: string | null;
      // TODO: ask JW. email => string|email
      email: string | unknown;
      phone: string | null;
      message: string | null;
    };
  };
  answers: {
    response: {
      status: 'ok' | 'error';
      answers: Answer[];
      count: number;
      message: string | null;
    };
    requestData: {
      all: 0 | 1;
    };
  };
  question: {
    response: {
      status: 'error';
    };
    requestData: {
      question: string;
      restart: 0 | 1;
    };
  };
  // noinspection SpellCheckingInspection
  rateanswers: {
    response: {
      status: 'error' | 'ok';
    };
    requestData: {
      id: number;
      rate: number;
    };
  };
};

enum ApiPaths {
  // noinspection SpellCheckingInspection
  CHAT_ACTIVE = '/active',
  CHAT_RESTART = '/restart',
  CHAT_CONTACTS = '/contacts',
  CHAT_ANSWERS = '/answers',
  CHAT_ASK = '/ask',
  CHAT_RATE_ANSWERS = '/rateanswers',
}

// noinspection SpellCheckingInspection
const apiCallConfigs: {
  [key in keyof ApiCallData]: {
    config: AxiosRequestConfig;
  };
} = {
  active: {
    config: {
      url: ApiPaths.CHAT_ACTIVE,
      // method: 'GET',
    },
  },
  restart: {
    config: {
      url: ApiPaths.CHAT_RESTART,
      // method: 'POST',
    },
  },
  contacts: {
    config: {
      url: ApiPaths.CHAT_CONTACTS,
    },
  },
  answers: {
    config: {
      url: ApiPaths.CHAT_ANSWERS,
    },
  },
  question: {
    config: {
      url: ApiPaths.CHAT_ASK,
    },
  },
  rateanswers: {
    config: {
      url: ApiPaths.CHAT_RATE_ANSWERS,
    },
  },
};

export const localStorageSSIDKey = 'questpair-ai-consultant-ssid';

export const activateChat = async () => {
  const activeResponse = await callAIConsultantApi('active', {});
  if (activeResponse.data.active && activeResponse.data.SSID) {
    localStorage.setItem(localStorageSSIDKey, activeResponse.data.SSID);
  } /* else {
    localStorage.removeItem(localStorageSSIDKey);
  }*/
  return activeResponse;
};

export const callAIConsultantApi = <T extends keyof ApiCallData>(
  type: T,
  extra: AxiosRequestConfig<
    ApiCallData[T]['requestData'] extends object
      ? ApiCallData[T]['requestData']
      : never
  >
) => {
  if (!API) {
    throw new Error('API should be created before calling');
  }
  const key = localStorage.getItem(localStorageSSIDKey);
  const { data: requestData, ...rest } = extra;
  let data: Record<string, unknown> = {};
  if (key) {
    data['SSID'] = key;
  }
  if (!isEmpty(requestData)) {
    data = {
      ...data,
      ...requestData,
    };
  }
  return API.request<ApiCallData[T]['response']>({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...apiCallConfigs[type].config,
    ...rest,
    ...(!isEmpty(data)
      ? {
          data,
        }
      : {}),
  });
};
