// Styled components with full height adjustment
import styled from "../../utils/styled";
import {cssClassPrefix, resetButtonStyles} from "../../styles";

export const Wrapper = styled.div<{
  $expanded: boolean;
}>`
position: fixed;
left: ${({ $expanded }) => ($expanded ? '0' : '20px')};
right: ${({ $expanded }) => ($expanded ? '0' : '20px')};
bottom: ${({ $expanded }) => ($expanded ? '0' : '20px')};
z-index: 1000;
width: ${({ $expanded }) => ($expanded ? '100%' : 'auto')};
height: ${({ $expanded }) => ($expanded ? '100vh' : 'auto')};
background: transparent;

overflow: hidden;
transition: all 0.3s ease-in-out;

@media (max-width: 768px) {
    left: ${({ $expanded }) => ($expanded ? '0' : '10px')};
    bottom: ${({ $expanded }) => ($expanded ? '0' : '10px')};
  }

  && {
    .${cssClassPrefix}-main-container {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      font-size: 16px;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .${cssClassPrefix}-chat-container {
      background-color: inherit;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .${cssClassPrefix}-message-list {
      border: none;
      background-color: inherit;
      flex-grow: 1;
      padding: 10px 10px 0 10px;

       @media (max-width: 768px) {
        padding: 8px 8px 0 8px;
      }
    }

    .${cssClassPrefix}-message {
      transition: all 0.3s ease-in-out;
      max-height: 3000px;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .${cssClassPrefix}-message + .${cssClassPrefix}-message:not(.${cssClassPrefix}-message_hidden) {
      margin-top: 16px;

      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }

    .${cssClassPrefix}-message_hidden {
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .${cssClassPrefix}-message-input {
      border-top: none;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .${cssClassPrefix}-message-input__content-editor-wrapper,
    .${cssClassPrefix}-message-input__content-editor-container,
    .${cssClassPrefix}-message-input__content-editor {
      background-color: transparent;
      color: ${({ theme }) => theme.getColor('text')};
    }

    .${cssClassPrefix}-message-list__scroll-wrapper > .${cssClassPrefix}-message,
    .${cssClassPrefix}-message-list__scroll-wrapper > .${cssClassPrefix}-message-group {
      max-width: initial;
    }

    .${cssClassPrefix}-message__content-wrapper {
      flex-grow: 1;
      max-width: 100%;
    }

    .${cssClassPrefix}-message__content {
      position: relative;
      color: ${({ theme }) => theme.getColor('text')};
      border-radius: 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      line-height: 1.5;

       @media (max-width: 768px) {
        border-radius: 16px;
      }
    }

    .${cssClassPrefix}-message__footer {
      align-self: flex-end;
      margin-top: 4px;
    }

     /* Swapped backgrounds - AI messages now have no background */
    .${cssClassPrefix}-message--incoming {
      .${cssClassPrefix}-message__content {
        border: none;
        background-color: transparent;
        box-shadow: 0 0px 0px;
        padding: 6px 2px;
        white-space: normal;

        @media (max-width: 768px) {
          padding: 4px 2px;
        }
      }
    }

    /* User messages now have the background color */
    .${cssClassPrefix}-message--outgoing {
      .${cssClassPrefix}-message__content {
        border: none;
        background-color: ${({ theme }) => theme.getColor('rgbaParadiso10')};
        border-radius: 10px;
      }
    }

    .${cssClassPrefix}-typing-indicator {
      background-color: transparent;
      height: 32px;
      left: 0.8em;
      margin-top: 8px;

      @media (max-width: 768px) {
        height: 28px;
        margin-top: 6px;
      }
    }

    .${cssClassPrefix}-avatar {
      margin-bottom: 25px;
      padding:3px;

      @media (max-width: 768px) {
        margin-bottom: 20px;
        padding: 2px;
        width: 28px;
        height: 28px;
      }
    }

    /* Markdown requires styling */
    .${cssClassPrefix}-message__content p{
      font-size: 15px;
      margin: 0;
      font-weight: 400;
    }
    .${cssClassPrefix}-message__content h1,
    .${cssClassPrefix}-message__content h2,
    .${cssClassPrefix}-message__content h3,
    .${cssClassPrefix}-message__content h4{
      margin-top: 0.4em;
      margin-bottom: 0.2em;
    }
    .${cssClassPrefix}-message__content ul{
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .${cssClassPrefix}-message__content p code{
      color: #3C8D8E;
    }
    .${cssClassPrefix}-message__content pre{
      border: 1px solid #ddd;
      padding: 10px;
    }
    .${cssClassPrefix}-message__content blockquote{
      background: #ddd;
      padding: 10px 20px;
      margin-inline-start: 20px;
      margin-inline-end: 20px;
    }
  }
`;

export const ChatTabButton = styled.button`
  ${resetButtonStyles};
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 14px 24px;
  background: linear-gradient(135deg, ${({ theme }) => theme.getColor('accentPrimary')} 0%, ${({ theme }) => theme.getColor('accentPrimary')}dd 100%);
  color: white;
  font-weight: 600;
  font-size: 16px;
  border-radius: 100px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, ${({ theme }) => theme.getColor('accentPrimary')}ee 0%, ${({ theme }) => theme.getColor('accentPrimary')} 100%);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-3px);

    margin-top: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.12);

    &:before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.08);
  }

  svg {
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    padding: 12px 18px;
    font-size: 14px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ChatContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color:rgb(255, 255, 255);
`;

export const ChatSection = styled.div`
  width: 100vw;
  height: 100%;
  margin: 0 auto; /* Centers horizontally */
  overflow: hidden;

  @media (max-width: 768px) {
    flex: 0 0 100%;
}
`;

// Modify the ExpandedChatContainer
export const ExpandedChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 900px;

  height: 91vh;
  padding: 0;

`;



/* export const MagicBoxSideContainer = styled.div`
  flex: 0 0 60%; // Take up 20% of the width
  height: 100vh;
  background-color: #f5f7f7;
  border-left: 1px solid #E4E9E9;
  overflow-y: auto;

  @media (max-width: 768px) {
    flex: 0 0 100%;
}
`; */


export const ChatTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;


export const AvatarWrapper = styled.div`
  background: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatControls = styled.div`
  display: flex;
  gap: 8px;
`;

export const IconButton = styled.button<{ disabled?: boolean }>`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color:  #3C8D8E;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '0.9')};
  transition: all 0.2s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover:not(:disabled) {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
  }
`;


/* export const IconMagicButton = styled.button<{ disabled?: boolean }>`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20%;
  color: theme.getColor('accentPrimary');
  opacity: ${({ disabled }) => (disabled ? '0.5' : '0.9')};
  transition: all 0.2s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover:not(:disabled) {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }
`; */


export const SendButton = styled.button<{ loading?: string; disabled?: boolean }>`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme, disabled }) =>
  disabled
    ? theme.getColor('silverChalice')
    : theme.getColor('accentPrimary')
};
  color: white;
  transition: all 0.2s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover:not(:disabled) {
    transform: scale(1.05);
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
  padding: 20px;
`;

export const LoadingText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.getColor('text')};
  text-align: center;
`;

export const Notice = styled.p`
  font-size: 10px;
  color: #777;
  margin-top: 0px;
  text-align: center;
  content-align: center;
`;


export const ChatHeader = styled.div`
  display: flex;
  width: 100vw;
  align-items: left;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
`;

export const BetaBadge = styled.span`
  display: inline-block;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.getColor('accentPrimary')};
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin-left: 8px;
`;
