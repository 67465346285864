import { DefaultTheme, Breakpoint } from 'styled-components';
import { isNumber } from 'lodash-es';
import {
  simpleColors,
  gradientColors,
  rgbaColors,
  typedColors,
} from './colors';
import { mobileDefaultFontSize, desktopDefaultFontSize } from './consts';

const colors = {
  ...simpleColors,
  ...gradientColors,
  ...rgbaColors,
  ...typedColors,
};

export const breakPoints: {
  [key in Breakpoint]: number;
} = {
  sm: 600,
  md: 1280,
};

export const getTheme: (rootId: string) => DefaultTheme = (rootId) => ({
  rootId,
  colors: colors,
  pxToRem: (px, mobile = false) =>
    `${px / (mobile ? mobileDefaultFontSize : desktopDefaultFontSize)}rem`,
  getColor: (colorName) => colors[colorName],
  getDownMedia: (breakPoint) => `
    @media screen and (max-width: ${
      isNumber(breakPoint) ? breakPoint : breakPoints[breakPoint]
    }px)
  `,
  getUpMedia: (breakPoint) => `
    @media screen and (min-width: ${
      isNumber(breakPoint) ? breakPoint : breakPoints[breakPoint]
    }px)
  `,
});
