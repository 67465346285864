import { Providers } from '../providers';
import { Chat } from './Chat.tsx';

export type AppProps = {
  rootId: string;
};

const App = ({ rootId }: AppProps) => (
  <Providers rootId={rootId}>
    <Chat />
  </Providers>
);

export { App };
