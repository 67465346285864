import { ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import { BeatLoader } from 'react-spinners';

export type SpinnerProps = ComponentProps<typeof BeatLoader>;

const Spinner = (props: SpinnerProps) => {
  const theme = useTheme();
  return <BeatLoader color={theme.getColor('accentPrimary')} {...props} />;
};

export { Spinner };
