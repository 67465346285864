// noinspection JSUnusedGlobalSymbols

export const simpleColors = {
  black: '#000',
  imperor: '#555555',
  doveGray: '#616161',
  white: '#fff',
  wildSand: '#f4f4f4',
  alto: '#d9d9d9',
  silverChalice: '#9D9D9D',
  persianRed: '#d32f2f',
  green: '#00FF00',
  paradiso: '#3C8D8E',
  webOrange: '#ffa800',
} as const;

export type SimpleColorName = keyof typeof simpleColors;
export type SimpleColorValue = (typeof simpleColors)[SimpleColorName];

export const gradientColors = {
  /*gradientBackground: `linear-gradient(108.04deg, #E5EFFB 0.5%, #EEF5F4 51.27%, #F3EEFC 99.44%)`,
  gradientBackground2: `linear-gradient(108.04deg, #EFFDFC 0.5%, #D3E3F7 46.64%, #EEE2FC 99.44%)`,*/
} as const;

export type GradientColorName = keyof typeof gradientColors;
export type GradientColorValue = (typeof gradientColors)[GradientColorName];

export const rgbaColors = {
  rgbaParadiso10: 'rgba(60, 141, 142, 0.1)',
  /*rgbaBorderModalColor: 'rgba(51,51,51, 0.5)',
  rgbaDividerColor: 'rgba(255, 255, 255, 0.1)',
  whiteTransparentColor: 'rgba(255, 255, 255, 0.25)',
  whiteTransparentColor2: 'rgba(255, 255, 255, 0.5)',*/
};

export type RgbaColorName = keyof typeof rgbaColors;
export type RgbaColorValue = (typeof rgbaColors)[RgbaColorName];

export const typedColors = {
  error: simpleColors.persianRed,
  warn: simpleColors.webOrange,
  text: simpleColors.imperor,
  background: simpleColors.wildSand,
  success: simpleColors.green,
  accentPrimary: simpleColors.paradiso,
  accentSecondary: simpleColors.webOrange,
};

export type TypedColorName = keyof typeof typedColors;
export type TypedColorValue = (typeof typedColors)[TypedColorName];

export type ColorName =
  | SimpleColorName
  | GradientColorName
  | RgbaColorName
  | TypedColorName;
export type ColorValue =
  | SimpleColorValue
  | GradientColorValue
  | RgbaColorValue
  | TypedColorValue;
