import { isFunction, isObject } from 'lodash-es';
import { SiteLinks, externalLinkProps } from '../../utils/consts.ts';
import styled from '../../utils/styled.ts';
import { resetButtonStyles } from '../../styles';
import { useModals } from '../../providers';
import { Button } from '../buttons';
import { Text, Link } from '../texts';

const InitialModal = () => {
  const { dispatch, data } = useModals();

  return (
    <ModalContainer>
      <CloseCrossButton onClick={() => dispatch(['closeModal'])}>
        X
      </CloseCrossButton>
      <Title color={'accentPrimary'}>Meet Your Lab Equipment Expert</Title>
      <Subtitle>Discover the perfect lab equipment with our AI consultant</Subtitle>
      
      <BenefitsList>
        <BenefitItem>
          <BenefitIcon>✓</BenefitIcon>
          <BenefitText>Get personalized equipment recommendations based on your research needs</BenefitText>
        </BenefitItem>
        <BenefitItem>
          <BenefitIcon>✓</BenefitIcon>
          <BenefitText>Compare options and make informed decisions in minutes, not hours</BenefitText>
        </BenefitItem>
        <BenefitItem>
          <BenefitIcon>✓</BenefitIcon>
          <BenefitText>Access expert knowledge on specifications, compatibility, and best practices</BenefitText>
        </BenefitItem>
      </BenefitsList>
      
      <Text
        style={{
          marginTop: 15,
          marginBottom: 10,
        }}
        align={'center'}
      >
        Our AI consultant is always ready at the left of your screen. Just click on th expert!
      </Text>
      
      <Link href={SiteLinks.READ_MORE} align={'center'} {...externalLinkProps}>
        {'Learn more about how it works >'}
      </Link>
      
      <Buttons>
        <TryButton
          onClick={() => {
            if (dataHasActivateChatFn(data)) {
              data.activateChat();
            }
            dispatch(['closeModal']);
          }}
        >
          Try the AI Consultant Now
        </TryButton>
        <CloseButton
          variant={'outlined'}
          onClick={() => dispatch(['closeModal'])}
        >
          {'Maybe later'}
        </CloseButton>
      </Buttons>
      
      <Testimonial>
        "The AI consultant helped me find exactly the right centrifuge for our new project in just 2 minutes!" - Molecular Chemistry Lab Researcher
      </Testimonial>
    </ModalContainer>
  );
};

const activateChatFnName = 'activateChat';
const dataHasActivateChatFn = (
  data: unknown
): data is {
  [activateChatFnName]: () => void;
} =>
  isObject(data) &&
  activateChatFnName in data &&
  isFunction(data[activateChatFnName]);

const ModalContainer = styled.div`
  position: relative;
  padding: 10px;
`;

const Title = styled(Text).attrs({
  component: 'h1',
})`
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 18px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.getColor('accentPrimary')};
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const BenefitIcon = styled.span`
  color: ${({ theme }) => theme.getColor('accentPrimary')};
  font-weight: bold;
  margin-right: 10px;
  font-size: 18px;
`;

const BenefitText = styled(Text)`
  font-size: 16px;
  line-height: 1.4;
`;

const CloseCrossButton = styled.button`
  ${resetButtonStyles};
  padding: 10px;
  font-size: 32px;
  line-height: 1;
  font-family: Roboto, sans-serif;
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${({ theme }) => theme.getColor('accentPrimary')};
  ${({ theme }) => theme.getUpMedia('sm')} {
    display: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  > button {
    width: 220px;
    ${({ theme }) => theme.getDownMedia('sm')} {
      width: 100%;
    }
  }
`;

const TryButton = styled(Button)`
  background-color: ${({ theme }) => theme.getColor('accentPrimary')};
  font-weight: bold;
  padding: 12px 20px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const CloseButton = styled(Button)`
  ${({ theme }) => theme.getDownMedia('sm')} {
    display: none;
  }
`;

const Testimonial = styled(Text)`
  font-style: italic;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  background: ${({ theme }) => `${theme.getColor('accentPrimary')}10`};
  border-radius: 8px;
`;

export { InitialModal };