import { Field } from 'formik';
import { Input, InputProps } from '../form-elements';

export type FormikInputProps = InputProps & {
  name: string;
};


export const FormikInput = ({ name, ...props }: InputProps & { name: string }) => (
  <Field name={name}>
    {({ field, meta }: any) => {
      // Check if this is the message field to render as textarea
      const isMessageField = name === 'message';
      
      return (
        <Input
          {...field}
          as={isMessageField ? 'textarea' : 'input'}
          isTextarea={isMessageField}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : undefined}
          {...props}
        />
      );
    }}
  </Field>
);
