import { ComponentProps } from 'react';
import color from 'color';
import styled from '../../utils/styled.ts';
import { resetButtonStyles } from '../../styles';
import { Spinner } from '../Spinner.tsx';
import { SendIcon } from '../icons';

export type SendButtonProps = ComponentProps<typeof Button> & {
  loading?: boolean;
};

const SendButton = ({ loading = false, ...props }: SendButtonProps) => (
  <Button {...props}>{loading ? <Spinner size={10} /> : <SendIcon />}</Button>
);

const Button = styled.button`
  ${resetButtonStyles};
  transition: color 0.3s ease-in-out;
  color: ${({ theme }) => theme.getColor('accentPrimary')};

  &:hover {
    color: ${({ theme }) =>
      color(theme.getColor('accentPrimary')).darken(0.3).hex()};
  }
`;

export { SendButton };
