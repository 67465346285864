import {
  useReducer,
  useMemo,
  useContext,
  createContext,
  PropsWithChildren,
  Reducer,
  Dispatch,
  ComponentType,
} from 'react';
import { isObject } from 'lodash-es';
import ReactModal from 'react-modal';
import styled from '../utils/styled';
// import { Modal as DefModal, IconButton } from '@material-ui/core';
// import { Close } from '@material-ui/icons';
import { InitialModal } from '../components/modals';

const Modals = ({ children }: PropsWithChildren<$TSFixMe>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { modalOpened, Content, data } = state;

  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);

  const closeModal = () => dispatch(['closeModal']);

  return (
    <ModalsContext.Provider value={contextValue}>
      {children}
      <ReactModal
        className={'ReactModal-center-modal-content'}
        overlayClassName={'ReactModal-center-modal-overlay'}
        isOpen={modalOpened}
        onRequestClose={closeModal}
      >
        <ContentWrapper>
          {/*<CloseButton aria-label={'Close'} onClick={closeModal}>
           <Close />
           </CloseButton>*/}
          <Content {...(isObject(data) ? data : {})} />
        </ContentWrapper>
      </ReactModal>
    </ModalsContext.Provider>
  );
};

ReactModal.setAppElement('body');

/*const Modal = styled(DefModal)`
 display: flex;
 justify-content: center;
 align-items: center;
 `;*/

/*const CloseButton = styled(IconButton)`
 position: absolute;
 top: 0;
 right: 0;
 color: #fff;
 `;*/

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 676px;
  background: ${({ theme: { getColor } }) => getColor('background')};
  padding: 34px 40px 23px;
  outline: none;
  position: relative;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 60px 15px 32px;
  }
`;

type State = {
  modalOpened: boolean;
  Content: ComponentType;
  data: unknown;
};

const initialState: State = {
  modalOpened: false,
  Content: () => null,
  data: undefined,
};

const modals = {
  initial: InitialModal,
} as const;

export type ModalContentType = keyof typeof modals;

type Action = ['closeModal'] | ['setModalContent', ModalContentType, unknown?];

const reducer: Reducer<State, Action> = (
  prevState,
  [type, modalType, data]
) => {
  switch (type) {
    case 'closeModal':
      return initialState;
    case 'setModalContent': {
      const Content = !!modalType && modals[modalType];

      return !Content
        ? initialState
        : {
            modalOpened: true,
            Content,
            data,
          };
    }
    default:
      return prevState;
  }
};

export const ModalsContext = createContext<
  State & {
    dispatch: Dispatch<Action>;
  }
>({
  ...initialState,
  dispatch: () => undefined,
});

export const useModals = () => useContext(ModalsContext);

export { Modals };
