import { ComponentProps } from 'react';
import styled from '../../utils/styled.ts';
import { Text } from './Text.tsx';

// export type LinkProps = TextProps<'a'>;
export type LinkProps = ComponentProps<typeof StyledText>;

const Link = (props: LinkProps) => <StyledText {...props} />;

const StyledText = styled(Text).attrs({
  component: 'a',
})`
  color: ${({ theme }) => theme.getColor('accentPrimary')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export { Link };
