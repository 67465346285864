import { PropsWithChildren } from 'react';
// import { Dates } from './Dates';
import { Modals } from './Modals';
import { Notifications } from './Notifications.tsx';
import { Styles } from './Styles';

export type ProvidersProps = PropsWithChildren<{
  rootId: string;
}>;

const Providers = ({ rootId, children }: ProvidersProps) => (
  <Styles rootId={rootId}>
    <Modals>{children}</Modals>
    <Notifications />
  </Styles>
);

export { Providers };
