import { useMemo, useState, useEffect } from 'react';
import { isNil, toNumber } from 'lodash-es';
import { SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { AnswerItem } from '../../utils/api.ts';
import { externalLinkProps } from '../../utils/consts.ts';
import styled, { css } from '../../utils/styled.ts';
import { Button } from '../buttons';
import { Swiper as DefSwiper, SwiperProps } from '../slider-elements';

export type ProductsBlockProps = SwiperProps & {
  products: AnswerItem[] | ReadonlyArray<AnswerItem>;
};

const ProductsBlock = ({ products, ...props }: ProductsBlockProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkIsMobile();

    // Listen for resize events
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const defProductsSwiperProps = useMemo(
    () => getDefProductsSwiperProps(products.length),
    [products.length]
  );

  return (
    <Wrapper $expanded={expanded}>
      <Swiper {...defProductsSwiperProps} {...props}>
        {products.map((product) => (
          <SwiperSlide key={product.url}>
            {isMobile ? (
              <MobileProduct
                className={product.className}
                href={product.url}
                {...externalLinkProps}
              >
                {product.className && (
                  <LabelContainer $isMobile={isMobile}>
                    {product.className?.includes('label-new') && <Label variant="new">New</Label>}
                    {product.className?.includes('label-instock') && <Label variant="instock">In Stock</Label>}
                    {product.className?.includes('label-trusted') && <Label variant="trusted">Trusted</Label>}
                    {product.className?.includes('label-external') && <Label variant="external">External</Label>}
                    {product.className?.includes('label-unavailable') && <Label variant="unavailable">Unavailable</Label>}
                  </LabelContainer>
                )}

                <MobileLayout>
                  <MobileImageContainer>
                    <ProductImage src={product.cover.replace('_', 'Q')} alt={`${product.title}-cover`} />
                  </MobileImageContainer>

                  <MobileContent>
                    <TitleContainer>
                      <ProductTitle $isMobile={isMobile}>{product.title}</ProductTitle>
                    </TitleContainer>

                    <BottomSection $isMobile={isMobile}>
                      {!isNil(product.price) && product.currency && (
                        <PriceContainer>
                          <ProductPrice $isMobile={isMobile}>
                            {toNumber(product.price).toLocaleString('nl-NL', {
                              style: 'currency',
                              currency: product.currency,
                            })}
                          </ProductPrice>
                        </PriceContainer>
                      )}
                      <MobileButtonContainer>
                        <QuoteButton $isMobile={isMobile}>Request</QuoteButton>
                      </MobileButtonContainer>
                    </BottomSection>
                  </MobileContent>
                </MobileLayout>
              </MobileProduct>
            ) : (
              <Product
                className={product.className}
                href={product.url}
                {...externalLinkProps}
              >
                {product.className && (
                  <LabelContainer $isMobile={false}>
                    {product.className?.includes('label-new') && <Label variant="new">New Supplier</Label>}
                    {product.className?.includes('label-instock') && <Label variant="instock">In Stock</Label>}
                    {product.className?.includes('label-trusted') && <Label variant="trusted">Trusted Supplier</Label>}
                    {product.className?.includes('label-external') && <Label variant="external">External</Label>}
                    {product.className?.includes('label-unavailable') && <Label variant="unavailable">Not Available</Label>}
                  </LabelContainer>
                )}
                <ProductImageContainer>
                  <ProductImage src={product.cover.replace('_', 'Q')} alt={`${product.title}-cover`} />
                </ProductImageContainer>
                <ProductContent>
                  <TitleContainer>
                    <ProductTitle $isMobile={false}>{product.title}</ProductTitle>
                  </TitleContainer>
                  <DescriptionContainer>
                    <ProductDesc>{product.description}</ProductDesc>
                  </DescriptionContainer>
                  <BottomSection $isMobile={false}>
                    {!isNil(product.price) && product.currency && (
                      <PriceContainer>
                        <ProductPrice $isMobile={false}>
                          {toNumber(product.price).toLocaleString('nl-NL', {
                            style: 'currency',
                            currency: product.currency,
                          })}
                        </ProductPrice>
                      </PriceContainer>
                    )}
                    <QuoteButtonContainer>
                      <QuoteButton $isMobile={false}>Request a quote</QuoteButton>
                    </QuoteButtonContainer>
                  </BottomSection>
                </ProductContent>
              </Product>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {!expanded && products.length > 2 && (
        <ExpandButton
          $expanded={expanded}
          size={'small'}
          onClick={() => {
            setExpanded(true);
          }}
        >
          Show More
        </ExpandButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  $expanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: ${({ $expanded }) => ($expanded ? '10000px' : '500px')};
  overflow: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  transition: max-height 0.3s ease-in-out;
  padding-bottom: ${({ $expanded }) => ($expanded ? '0' : '60px')};

  &:after {
    content: '';
    height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${({ $expanded }) => ($expanded ? '0' : '1')};
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  @media (max-width: 767px) {
    max-height: ${({ $expanded }) => ($expanded ? '10000px' : '400px')};
  }
`;

const ExpandButton = styled(Button)<{
  $expanded: boolean;
}>`
  width: 200px;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 0.5rem;
  font-weight: 600;
  text-transform: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 767px) {
    width: 150px;
    font-size: 0.85rem;
    padding: 0.5rem 0;
  }
`;

const Swiper = styled(DefSwiper)`
  &.swiper {
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      padding: 15px 30px;
    }

    @media (max-width: 480px) {
      padding: 10px 25px;
    }
  }

  .swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    color: ${({ theme }) => theme.getColor('accentPrimary')};

    &:after {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      background-color: ${({ theme }) => theme.getColor('accentPrimary')};
      color: white;
    }

    @media (max-width: 767px) {
      width: 30px;
      height: 30px;

      &:after {
        font-size: 12px;
      }
    }
  }

  .swiper-button-prev {
    left: 10px;

    @media (max-width: 480px) {
      left: 5px;
    }
  }

  .swiper-button-next {
    right: 10px;

    @media (max-width: 480px) {
      right: 5px;
    }
  }
`;

const LabelContainer = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: ${({ $isMobile }) => $isMobile ? 'flex-start' : 'center'};
  z-index: 1;
`;

const Label = styled.div<{
  variant: 'new' | 'instock' | 'trusted' | 'external' | 'unavailable';
}>`
  padding: 4px 16px;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
  font-weight: 500;

  @media (max-width: 767px) {
    padding: 3px 10px;
    font-size: 0.7rem;
  }

  ${({ variant, theme }) => {
    const variants = {
      new: {
        backgroundColor: '#e6f7ff',
        color: '#000000',
      },
      instock: {
        backgroundColor: theme.getColor('accentPrimary'),
        color: '#ffffff',
      },
      trusted: {
        backgroundColor: theme.getColor('accentSecondary'),
        color: '#ffffff',
      },
      external: {
        backgroundColor: theme.getColor('doveGray'),
        color: '#ffffff',
      },
      unavailable: {
        backgroundColor: theme.getColor('error'),
        color: '#ffffff',
      },
    };
    return css`
      background-color: ${variants[variant].backgroundColor};
      color: ${variants[variant].color};
    `;
  }}
`;

// Desktop Product Card
const Product = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 425px;
  border-radius: 0.75rem;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-size: 15px;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

// Mobile Product Card
const MobileProduct = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

// Mobile Layout
const MobileLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const MobileImageContainer = styled.div`
  flex: 0 0 100px;
  position: relative;
  height: 100%;
  background-color: #f9fafb;
  overflow: hidden;
`;

const MobileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  justify-content: space-between;
`;

const ProductImageContainer = styled.div`
  position: relative;
  height: 180px;
  background-color: #f9fafb;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;

  ${Product}:hover & {
    transform: scale(1.05);
  }
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
`;

const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const ProductTitle = styled.h2<{ $isMobile: boolean }>`
  font-size: ${props => props.$isMobile ? '15px' : '15px'};
  line-height: 1.4;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.$isMobile ? '2' : '2'};
  overflow: hidden;
`;

const DescriptionContainer = styled.div`
  margin-bottom: 1rem;
`;

const ProductDesc = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4b5563;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const BottomSection = styled.div<{ $isMobile: boolean }>`
  margin-top: ${props => props.$isMobile ? '0' : 'auto'};
  display: flex;
  flex-direction: ${props => props.$isMobile ? 'row' : 'column'};
  align-items: ${props => props.$isMobile ? 'center' : 'stretch'};
  justify-content: ${props => props.$isMobile ? 'space-between' : 'flex-start'};
  gap: ${props => props.$isMobile ? '0.5rem' : '0.75rem'};
`;

const PriceContainer = styled.div`
  transition: transform 0.3s ease;

  ${Product}:hover & {
    transform: scale(1.05);
  }
`;

const ProductPrice = styled.div<{ $isMobile: boolean }>`
  font-size: ${props => props.$isMobile ? '15px' : '16px'};
  font-weight: 600;
  color: #111827;
  white-space: nowrap;
  transition: transform 0.3s ease;

  ${Product}:hover & {
    transform: translate(10px, -4px);
  }
`;

const QuoteButtonContainer = styled.div`
  width: 100%;
  transition: transform 0.3s ease;

  ${Product}:hover & {
    transform: translateY(-4px);
  }
`;

const MobileButtonContainer = styled.div`
  transition: transform 0.3s ease;
`;

const QuoteButton = styled.button<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => props.$isMobile ? '32px' : '40px'};
  font-size: ${props => props.$isMobile ? '14px' : '14px'};
  padding: ${props => props.$isMobile ? '0 0.75rem' : '0 1rem'};
  font-weight: 600;
  text-transform: none;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.getColor('accentPrimary')};
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.getColor('accentPrimary') + 'dd'};
  }
`;

const getDefProductsSwiperProps: (productsAmount: number) => SwiperOptions = (
  productsAmount
) => ({
  // Show exactly 2 slides per view for all breakpoints except very small screens
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        enabled: productsAmount > 1,
      },
    },
    540: {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        enabled: productsAmount > 2,
      },
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        enabled: productsAmount > 2,
      },
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        enabled: productsAmount > 2,
      },
    },
    1280: {
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        enabled: productsAmount > 2,
      },
    },
  },
});

export { ProductsBlock };
