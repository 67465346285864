import { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

interface TypewriterTextProps {
  text: string;
  sentTime: string | undefined;
  speed?: number;
  onComplete?: () => void;
  onTypingStart?: () => void;
  messageId?: string | number;
}

const TypewriterText: React.FC<TypewriterTextProps> = ({
   text,
   sentTime,
   speed = 20,
   onComplete,
   onTypingStart,
   messageId = 'default'
 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const timerRef = useRef<number | null>(null);
  const storageKey = `typewriter-${messageId}`;

  const isRecent = (timestamp: string, seconds: number): boolean => {
    if (!timestamp) return false;
    const givenTime = new Date(timestamp);
    const currentTime = new Date();
    return (currentTime.getTime() - givenTime.getTime()) / 1000 < seconds;
  }

  useEffect(() => {
    // Check if this message should animate (recent) or show immediately
    const shouldAnimate = sentTime && isRecent(sentTime, 5);

    // Clear any existing timers
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }

    if (shouldAnimate) {
      // Start with empty text for animation
      setDisplayedText('');

      // Notify that typing has started
      onTypingStart?.();

      let currentIndex = 0;
      const typeNextChar = () => {
        if (currentIndex < text.length) {
          // Increment by 3 characters at a time for better performance
          currentIndex = Math.min(currentIndex + 3, text.length);
          setDisplayedText(text.slice(0, currentIndex));

          // Schedule the next update
          timerRef.current = window.setTimeout(typeNextChar, speed);
        } else {
          // Animation complete
          localStorage.setItem(storageKey, 'complete');
          onComplete?.();
        }
      };

      // Start the animation after a short delay
      timerRef.current = window.setTimeout(typeNextChar, speed);
    } else {
      // Show full text immediately
      setDisplayedText(text);
      onComplete?.();
    }

    // Cleanup function
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [text, sentTime, speed, onComplete, onTypingStart, messageId, storageKey]);

  return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};

export default TypewriterText;
