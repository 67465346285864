import { RefObject } from 'react';
import {Avatar, MessageInput} from '@questpair/chat-ui-kit-react';
import { Turnstile } from '@marsidev/react-turnstile';
import {Spinner} from "../Spinner";
import botAvatar from '../../assets/img/chatbot.jpeg';
import styled from "../../utils/styled";
import {cssClassPrefix} from "../../styles";
import {CrossIcon, ArrowRightIcon} from "./ChatIcons";
import {SendButton, IconButton} from "./ChatStyle"

interface WelcomeScreenProps {
  inputRef: RefObject<HTMLDivElement>;
  onExpandClick: () => void;
  handleTurnstileVerify: (token: string) => Promise<void>;
  handleSend: (question: string) => Promise<void>;
  startNewChat: () => void;
  captchIsSet: boolean;
  verifyingCaptcha: boolean;
  chatIsActive: boolean;
  sendingMessage: boolean;
  msgInput: {
    innerHtml: string;
    innerText: string;
  };
  setMsgInput: (input: { innerHtml: string; innerText: string }) => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
                                                       inputRef,
                                                       onExpandClick,
                                                       handleTurnstileVerify,
                                                       handleSend,
                                                       startNewChat,
                                                       captchIsSet,
                                                       verifyingCaptcha,
                                                       chatIsActive,
                                                       sendingMessage,
                                                       msgInput,
                                                       setMsgInput
                                                     }) => {
  return (
    <WelcomeContainer>
      <IconButton
        onClick={onExpandClick}
        aria-label="Close chat"
        title="Close chat"
        style={{ position: "absolute", top: 0, right: 0, margin: 10, width: 40, height: 40 }}
      >
        <CrossIcon />
      </IconButton>
      <WelcomeContent>
        <LogoContainer>
          <Avatar src={botAvatar} name="QuestPair AI" />
          <WelcomeTitle>QuestPair Research Assistant</WelcomeTitle>
        </LogoContainer>

        <WelcomeDescription>
          Your AI-powered guide to finding the best lab equipment for your research
        </WelcomeDescription>

        <FeatureList>
          <FeatureItem>
            <FeatureIcon>✓</FeatureIcon>
            <FeatureText>Get personalized recommendations based on your research requirements</FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>✓</FeatureIcon>
            <FeatureText>Compare equipment options effortlessly to make informed decisions</FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>✓</FeatureIcon>
            <FeatureText>Access expert knowledge on specifications and compatibility</FeatureText>
          </FeatureItem>
        </FeatureList>

        {/* Captcha Section */}
        {!captchIsSet && (
          <CaptchaSection>
            {verifyingCaptcha ? (
              <VerifyingContainer>
                <Spinner />
                <VerifyingText>Verifying...</VerifyingText>
              </VerifyingContainer>
            ) : (
              <Turnstile
                siteKey={import.meta.env.VITE_TURNSTILE}
                onSuccess={handleTurnstileVerify}
                onError={() => {
                  console.error("Verification failed");
                }}
                options={{
                  theme: 'light',
                  refreshExpired: 'auto'
                }}
              />
            )}
          </CaptchaSection>
        )}

        {/* Message Input Container */}
        <div
          // @ts-expect-error library's prop not updated
          as={StyledMessageInput}
          style={{
            width: '100%', // Full width
            display: 'flex',
            flexDirection: 'row',
            borderRadius: "24px", // Fully rounded corners
            paddingRight: 17,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            padding: "5px 8px",
            alignItems: "center",
            margin: "5px", // Slight margin for spacing
            background: "#E4E9E9",
          }}
        >
          <StyledMessageInput
            ref={inputRef}
            plaintextOnly={navigator.userAgent.match(/firefox|fxios/i) ? false : true}
            disabled={!chatIsActive || !captchIsSet || sendingMessage}
            sendOnReturnDisabled={false}
            sendButton={false}
            attachButton={false}
            placeholder="Type message here"
            value={msgInput.innerHtml}
            onChange={(innerHtml: string, _: any, innerText: string) => {
              setMsgInput({
                innerHtml,
                innerText,
              });
            }}
            onSend={handleSend}
          />
          <SendButton
            loading={sendingMessage.toString()}
            disabled={msgInput.innerHtml.length === 0}
            onClick={() => {
              handleSend(msgInput.innerText);
              startNewChat();
            }}
          >
            <ArrowRightIcon />
          </SendButton>
        </div>

        <BetaNotice>Beta version - We appreciate your feedback</BetaNotice>
      </WelcomeContent>
    </WelcomeContainer>
  );
};

//Welcom Page

const WelcomeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #f9f9f9;

  && {
  .${cssClassPrefix}-message-input__content-editor-wrapper,
    .${cssClassPrefix}-message-input__content-editor-container,
    .${cssClassPrefix}-message-input__content-editor{
    background-color: #E4E9E9;
    color: ${({ theme }) => theme.getColor('text')};
    text-align: left;
  }

}
`;

const StyledMessageInput = styled(MessageInput)`
  flex-grow: 1;
  flex-shrink: initial;
  font-size: 18px;
  border-radius: 25px;
  padding: 0px;
  margin-right: 12px;

`;

const WelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 40px 20px;
  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  .${cssClassPrefix}-avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }
`;

const WelcomeTitle = styled.h1`
  font-size: 32px;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const WelcomeDescription = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 32px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  width: 100%;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 16px;
`;

const FeatureIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.getColor('accentPrimary')};
  color: white;
  margin-right: 12px;
  flex-shrink: 0;
`;

const FeatureText = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const BetaNotice = styled.p`
  font-size: 14px;
  color: #777;
  margin-top: 24px;
`;

// Additional styled components for Captcha section
const CaptchaSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const VerifyingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const VerifyingText = styled.p`
  font-size: 14px;
  color: #555;
`;

export default WelcomeScreen;
