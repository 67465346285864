import { createGlobalStyle } from 'styled-components';
// import { normalize } from 'styled-normalize';
import {
  desktopDefaultFontSize,
  fallbackFonts,
  mobileDefaultFontSize,
} from './consts';

const GlobalStyles = createGlobalStyle`
  #${({ theme }) => theme.rootId} {
    max-width: 100%;
    font-size: ${desktopDefaultFontSize}px;
    font-weight: 400;
    font-family: Roboto, ${fallbackFonts};
    line-height: 19px;
    color: ${({ theme }) => theme.getColor('imperor')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
      font-size: ${mobileDefaultFontSize}px;
      line-height: 1.5;
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    img {
      display: block;
      max-width: 100%;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    }

    /*h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }*/

    .reset-button-styles {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;

      background: transparent;

      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;

      /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
      line-height: normal;

      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      /* Corrects inability to style clickable \`input\` types in iOS */
      -webkit-appearance: none;

      cursor: pointer;
    }
  }

  .ReactModalPortal {
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
  }

  .ReactModal-center-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    outline: none !important;
  }

  .ReactModal-center-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(70, 70, 70, 0.5);
  }
`;

export { GlobalStyles };
