import { ComponentProps, useState, useMemo } from 'react';
import styled from '../../utils/styled.ts';
import { Button } from '../buttons';
import { ContactsForm } from '../forms';
import { Text } from '../texts';

export type ContactsBlockProps = { titleMessage: string } & ComponentProps<
  typeof Wrapper
>;

const ContactsBlock = ({ titleMessage, ...props }: ContactsBlockProps) => {
  const [contentType, setContentType] = useState(ContentTypes.INITIAL);

  const content = useMemo(() => {
    switch (contentType) {
      case ContentTypes.INITIAL:
        return (
          <Button
            size={'small'}
            onClick={() => {
              setContentType(ContentTypes.FORM);
            }}
          >
            Contact us
          </Button>
        );
      case ContentTypes.FORM:
        return (
          <ContactsForm
            onSuccessSubmit={() => {
              setContentType(ContentTypes.RESULT);
            }}
          />
        );
      case ContentTypes.RESULT:
        return (
          <Text align={'center'} variant={'small'} weight={'medium'}>
            Contact form submitted
          </Text>
        );
      default:
        throw new Error(`No such contentType: ${contentType}`);
    }
  }, [contentType]);

  return (
    <Wrapper {...props}>
      <TitleText
        variant={'normal'}
        weight={'medium'}
        letterSpacing={'small'}
      >
        Let’s connect you with one of our team members! Fill out the form below, and we’ll get back to you as soon as possible. We'll  keep your information secure and only use it for support or updates.
      </TitleText>
      <ContentWrapper>{content}</ContentWrapper>
    </Wrapper>
  );
};

enum ContentTypes {
  INITIAL = 'initial',
  FORM = 'form',
  RESULT = 'result',
}

const Wrapper = styled('div')`
  padding: 16px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.getColor('background')};
  
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
`;

const TitleText = styled(Text)`
  margin-bottom: 30px;
  fontsize: 18px;
  padding-bottom: 20px;
`;

const ContentWrapper = styled('div')`
  width: 100%;
`;


export { ContactsBlock };
