import styled2 from 'styled-components';
import {
  css,
  createGlobalStyle,
  useTheme,
  DefaultTheme,
} from 'styled-components';
import { isFunction } from 'lodash-es';

let styled = styled2;

if (!isFunction(styled2.div)) {
  // @ts-expect-error for libraries issue
  styled = styled2.default;
}

export { css, createGlobalStyle, useTheme };
export type { DefaultTheme };

export default styled;
