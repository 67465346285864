import { ReactNode, InputHTMLAttributes } from 'react';
import styled, { css } from '../../utils/styled.ts';
import { Text } from '../texts';
import { fallbackFonts } from '../../styles/consts.ts';

export type InputProps = {
  error?: boolean;
  helperText?: ReactNode;
  isTextarea?: boolean;
} & (InputHTMLAttributes<HTMLInputElement>);

const Input = ({ error = false, helperText, isTextarea = false, ...props }: InputProps) => (
  <>
    <StyledInput $error={error} $isTextarea={isTextarea} {...props} />
    {!!helperText && (
      <HelperText $error={error} variant={'small'}>
        {helperText}
      </HelperText>
    )}
  </>
);

const StyledInput = styled.input<{
  $error: boolean;
  $isTextarea?: boolean;
}>`
  width: 100%;
  padding: 6px 14px;
  border: 1px solid
    ${({ theme, $error }) => theme.getColor($error ? 'error' : 'white')};
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  font-family: Roboto, ${fallbackFonts};
  line-height: ${16.41 / 14};
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.getColor('silverChalice')};
  }

  ${({ $isTextarea }) =>
    $isTextarea &&
    css`
      min-height: 120px;
      overflow: auto;
      resize: vertical;
      line-height: 1.5;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;
    `}
`;



const HelperText = styled(Text)<{
  $error: boolean;
}>`
  margin-top: 8px;

  ${({ $error }) =>
    $error &&
    css`
      color: #ff0000;
    `}
`;

export { Input };
