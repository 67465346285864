// noinspection JSUnusedGlobalSymbols
//
import { css } from 'styled-components';

export const resetListStyles = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

// noinspection JSUnusedGlobalSymbols
export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const resetButtonStyles = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;

  cursor: pointer;
`;

// noinspection CssInvalidPropertyValue,CssUnknownProperty
export const textGradient = (value: string) => css`
  background: linear-gradient(${value});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
