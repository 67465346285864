import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper as DefSwiper, SwiperProps } from 'swiper/react';

const Swiper = (props: SwiperProps) => (
  <DefSwiper modules={[Navigation]} navigation={true} {...props} />
);

export type { SwiperProps };

export { Swiper };
