import { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/400.css';
import { getTheme } from '../styles';
import { GlobalStyles } from '../styles/GlobalStyles.ts';

export type StylesProps = PropsWithChildren<{
  rootId: string;
}>;

const Styles = ({ rootId, children }: StylesProps) => (
  <ThemeProvider theme={getTheme(rootId)}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export { Styles };
