import { useMemo, ComponentProps } from 'react';
import toast from 'react-hot-toast';
import { Formik, Form as DefForm } from 'formik';
import * as Yup from 'yup';
import { callAIConsultantApi } from '../../utils/api.ts';
import styled from '../../utils/styled.ts';
import { Button as DefButton } from '../buttons';
import { FormikInput } from '../formik-elements';

export type ContactsFormProps = ComponentProps<typeof Form> & {
  onSuccessSubmit: () => void;
};

const ContactsForm = ({ onSuccessSubmit, ...props }: ContactsFormProps) => {
  const schema = useMemo(() => getSchema(), []);
  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        surname: '',
        company: '',
        email: '',
        phone: '',
        message: '',
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          const contactsResponse = await callAIConsultantApi('contacts', {
            data: {
              user_id: null,
              name: values.name,
              surname: values.surname || null,
              company: values.company || null,
              email: values.email,
              phone: values.phone || null,
              message: values.message || null,
            },
          });
          if (contactsResponse.data.status !== 'ok') {
            throw new Error('Response status is not ok');
          }
          onSuccessSubmit();
        } catch (e) {
          console.error(e);
          toast.error('Something went wrong, please try again');
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          {inputs.map(([key, { placeholder }]) => (
            <Input key={key} name={key} placeholder={placeholder} />
          ))}
          <Button type={'submit'} size={'small'} disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(DefForm)`
  flex-grow: 1;
`;

const Input = styled(FormikInput)`
  & + & {
    margin-top: 8px;
  }
`;

const Button = styled(DefButton)`
  margin-top: 20px;
`;

type FormValues = {
  name: string;
  surname: string;
  company: string;
  email: string;
  phone: string;
  message: string;
};

const inputsData: ReadonlyMap<
  keyof FormValues,
  {
    placeholder: string;
  }
> = new Map([
  [
    'name',
    {
      placeholder: 'Name',
    },
  ],
  [
    'surname',
    {
      placeholder: 'Surname',
    },
  ],
  [
    'company',
    {
      placeholder: 'Company',
    },
  ],
  [
    'email',
    {
      placeholder: 'Email',
    },
  ],
  [
    'phone',
    {
      placeholder: 'Phone number',
    },
  ],
  [
    'message',
    {
      placeholder: 'Message',
    },
  ],
]);

const inputs = Array.from(inputsData);

const minAmount = 1;
const maxAmount = 20;
const bigMaxAmount = 500;
const minAmountMsg = `Shouldn be less than ${minAmount} chars`;
const maxAmountMsg = `Shouldn be more than ${maxAmount} chars`;

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

const getSchema: () => Yup.ObjectSchema<FormValues> = () =>
  Yup.object({
    name: Yup.string()
      .min(minAmount, minAmountMsg)
      .max(maxAmount, maxAmountMsg)
      .required('Field is required'),
    surname: Yup.string()
      .test(
        'is-min',
        minAmountMsg,
        (value) => !value || value.length >= minAmount
      )
      .max(maxAmount, maxAmountMsg)
      .required('Field is required')
      .default(''),
    company: Yup.string()
      .test(
        'is-min',
        minAmountMsg,
        (value) => !value || value.length >= minAmount
      )
      .max(maxAmount, maxAmountMsg)
      .default(''),
    email: Yup.string().email('Email is invalid').required('Field is required'),
    phone: Yup.string()
      .test(
        'is-valid-phone',
        'Phone number is not valid',
        (value) => !value || phoneRegExp.test(value)
      )
      .default(''),
    message: Yup.string()
      .min(minAmount, minAmountMsg)
      .max(bigMaxAmount, maxAmountMsg)
      .required('Field is required'),
  });

export { ContactsForm };
